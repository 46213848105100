<template>
  <v-container class="general">
    <div v-if="isReady">
      <div
        v-for="(section, sectionIndex) in dashboardSections"
        :key="`section-${sectionIndex}`"
        class="dashboard-section"
      >
        <page-title :title="$t(section.title)">
          <template slot="actions">
            <VideoTutorial
              :component="section.tutorial"
              :title="$t(section.title)"
            />
          </template>
        </page-title>
        <div v-if="section.hint" class="mb-5 px-4 sw-h5">
          {{ $t(section.hint) }}
        </div>
        <div v-if="section.template === 'MultiRowSection'">
          <v-layout
            v-for="(row, rowIndex) in section.rows"
            :key="`row-${rowIndex}`"
            class="row wrap multi-row-section"
          >
            <v-flex
              :class="[
                'xs11 pl-4 multi-row-section-row',
                {
                  complete: isRowClosed(sectionIndex, rowIndex),
                },
              ]"
            >
              <v-layout class="row nowrap align-end mb-5">
                <v-flex style="width: 120px">
                  <span class="font-weight-bold sw-primary row-number">
                    {{ rowIndex + 1 }}
                  </span>
                </v-flex>
                <v-flex class="xs11">
                  <v-layout class="row nowrap align-center row-title-container">
                    <v-flex class="xs12 mb-2">
                      <span class="px-4 sw-h3 sw-primary row-title">
                        {{ $t(row.title) }}
                      </span>
                    </v-flex>
                    <font-awesome-icon
                      v-if="!isRowClosed(sectionIndex, rowIndex)"
                      :icon="['fas', 'check-circle']"
                      class="row-state"
                    />
                    <font-awesome-icon
                      v-if="isRowClosed(sectionIndex, rowIndex)"
                      :icon="['fas', 'check-circle']"
                      class="row-state closed cursor-pointer"
                      @click="openRowCards(sectionIndex, rowIndex)"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout
                v-if="!isRowClosed(sectionIndex, rowIndex)"
                class="row nowrap"
              >
                <v-flex style="width: 120px"></v-flex>
                <v-flex xs11>
                  <v-layout row wrap class="mx-n4">
                    <v-flex
                      v-for="(card, cardIndex) in row.cards"
                      :key="`card-${cardIndex}`"
                      :class="`px-4 ${card.class}`"
                    >
                      <v-card class="fill-height border-radius">
                        <div class="text-right">
                          <font-awesome-icon
                            v-if="isCardClosed(card.key)"
                            :icon="['fas', 'check-circle']"
                            class="card-state closed"
                          />
                          <font-awesome-icon
                            v-if="!isCardClosed(card.key)"
                            :icon="['fas', 'check-circle']"
                            class="card-state cursor-pointer"
                            @click="closeCard(card)"
                          />
                        </div>
                        <div class="px-4 pb-5 text-center">
                          <div class="mb-4 pa-0 sw-h5 font-weight-light">
                            {{ $t(card.title) }}
                          </div>
                          <div v-if="card.hint" class="mb-4">
                            {{ $t(card.hint) }}
                          </div>
                          <v-btn
                            round
                            class="text-none sw-accent-bg sw-on-accent"
                            @click="action(card.action)"
                          >
                            {{ $t(card.action.text) }}
                          </v-btn>
                        </div>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </div>
    <ConfirmationDialog ref="confirmation" />
  </v-container>
</template>

<script>
import { sections } from "@/components/GetStarted/sections.json";
import ConfirmationDialog from "@/components/GetStarted/ConfirmationDialog.vue";

export default {
  data: () => ({
    isReady: false,
    groupPlugins: [],
    dashboardStates: {},
  }),
  computed: {
    appName() {
      return process.env.VUE_APP_NAME;
    },
    groupId() {
      return this.$route.params.group_id;
    },
    dashboardSections() {
      return sections;
    },
  },
  components: {
    ConfirmationDialog,
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.listGroupPlugins();
        this.getUserStates();
      },
    },
  },
  methods: {
    async listGroupPlugins() {
      try {
        const params = [
          this.groupId,
          {
            with_attributes: 1,
            page: 1,
            per_page: 500,
          },
        ];

        const response = await this.$api.groupPlugins.list(...params);

        this.groupPlugins = response.data.data.map((el) => ({
          id: el.id,
          uid: el.attributes.uid,
        }));
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
      }
    },
    async getUserStates() {
      try {
        const params = { with_attributes: 1 };

        const response = await this.$api.auth.me(params);

        const states = JSON.parse(
          response.data.data.attributes.dashboard_states,
        );

        this.dashboardStates = this.mappedDashboardStates(states);
        this.isReady = true;
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
        this.isReady = true;
      }
    },
    async openRowCards(sectionIndex, rowIndex) {
      try {
        const section = this.dashboardSections[sectionIndex];
        const row = section.rows[rowIndex];
        const cardKeys = row.cards.map((card) => card.key);

        for (const key of cardKeys) {
          this.dashboardStates[key] = true;
        }

        const specs = {
          dashboard_states: JSON.stringify(this.dashboardStates),
        };

        await this.$api.auth.update(specs);
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
      }
    },
    isRowClosed(sectionIndex, rowIndex) {
      const section = this.dashboardSections[sectionIndex];
      const row = section.rows[rowIndex];
      const cardKeys = row.cards.map((card) => card.key);

      return cardKeys.every((key) => !this.dashboardStates[key]);
    },
    isCardClosed(cardKey) {
      return !this.dashboardStates[cardKey];
    },
    async closeCard(card) {
      try {
        const isConfirmed = await this.$refs.confirmation.open(
          card.confirmation,
          this.$t("dashboard.card_complete", { app_name: this.appName }),
        );

        if (!isConfirmed) return;

        this.$set(this.dashboardStates, [card.key], false);

        const specs = {
          dashboard_states: JSON.stringify(this.dashboardStates),
        };

        await this.$api.auth.update(specs);
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
      }
    },
    mappedDashboardStates(states) {
      const result = {};

      for (const section of this.dashboardSections) {
        if (section.template !== "MultiRowSection" || !section.rows) {
          continue;
        }

        for (const row of section.rows) {
          if (!row.cards) continue;

          for (const card of row.cards) {
            const state =
              states && typeof states[card.key] === "boolean"
                ? states[card.key]
                : true;

            this.$set(result, [card.key], state);
          }
        }
      }

      return result;
    },
    action(action) {
      if (action.name === "route-to") {
        this.routeTo(action.route);
      }
    },
    routeTo(route) {
      const completeRoute = {
        name: route.name,
        params: {},
        query: route.query,
      };

      if (route.params && route.params.plugin_uid) {
        const groupPlugin = this.groupPlugins.find(
          (el) => el.uid === route.params.plugin_uid,
        );

        if (groupPlugin && groupPlugin.id) {
          completeRoute.params.group_plugin_id = groupPlugin.id;
        }
      }

      this.$router.push(completeRoute, () => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-section {
  margin: 0 0 210px 0;

  .multi-row-section:not(:last-child) {
    margin: 0 0 48px 0;
  }

  .multi-row-section-row {
    transition: all 0.4s ease-in-out;
    transform-origin: 0 0;

    .row-number {
      position: relative;
      bottom: -18px;
      font-size: 120px;
      line-height: 1;
    }

    .row-title-container {
      margin: 0 96px 0 0;
      padding: 0 0 6px 0;
      border-bottom: solid 1px $primary;
    }

    &.complete {
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5);
      height: 90px;
      padding-left: 48px !important;

      .row-number {
        color: transparentize($primary, 0.6) !important;
      }

      .row-title-container {
        border-color: transparentize($primary, 0.6) !important;

        .row-title {
          color: transparentize($primary, 0.6) !important;
        }
      }
    }

    .row-state {
      font-size: 50px;
      color: $accent;

      &.closed {
        opacity: 0.25;
      }
    }

    .card-state {
      margin: 12px;
      font-size: 24px;
      color: $accent;

      &.closed {
        opacity: 0.25;
      }
    }
  }
}
</style>

<template>
  <v-dialog v-model="dialog" width="600">
    <v-card
      min-height="400"
      class="confirmation-card border-radius"
      :class="{ confirmed: confirmed }"
      :style="{ backgroundImage: backgroundImage }"
    >
      <v-card-actions
        ><v-spacer></v-spacer
        ><v-btn icon @click="dialog = false">
          <v-icon small>close</v-icon></v-btn
        ></v-card-actions
      >
      <v-card-text v-if="!confirmed" class="text-center">
        <div class="mb-5 headline font-weight-medium">{{ title }}</div>
        <v-btn round class="white sw-primary text-none" @click="cancel">
          {{ $t("common.no") }}
        </v-btn>
        <v-btn
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="confirm"
        >
          {{ $t("common.yes") }}
        </v-btn>
      </v-card-text>
      <v-card-text v-if="confirmed" class="text-center">
        <div class="mb-5 headline font-weight-medium">
          {{ confirmationText }}
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    params: [],
    confirmed: false,
    file: "url(" + require(`@/assets/images/file.png`) + ")",
    timeout: null,
  }),
  computed: {
    title() {
      return this.params[0];
    },
    confirmationText() {
      return this.params[1];
    },
    backgroundImage() {
      return this.confirmed
        ? "url(" + require(`@/assets/images/file.png`) + ")"
        : "url(" + require(`@/assets/images/file_doubt.png`) + ")";
    },
  },
  methods: {
    async open(...params) {
      clearTimeout(this.tiimeout);

      this.params = params;
      this.confirmed = false;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
      });
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
    confirm() {
      this.confirmed = true;
      this.resolve(true);

      this.tiimeout = setTimeout(() => {
        this.dialog = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
.confirmation-card {
  background-size: 130px auto;
  background-position: bottom 40px left 30px;

  &.confirmed {
    background-position: bottom 40px center;
  }
}
</style>
